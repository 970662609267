/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../css/pages/SaltedgeSuccess.module.scss';

import SEO from '../components/layout/SEO';

const SaltedgeRenewSuccess = (props) => {
    const spinner = '../images/spinner.gif';

    const redirect = props.location.search
        ? `https://app.roundups.org/dashboard/saltedge-renew-success/${props.location.search}`
        : 'https://app.roundups.org/dashboard';

    setTimeout(() => {
        if (typeof window !== 'undefined') {
            window.location.href = redirect;
        }
    }, 250);

    return (
        <>
            <SEO title="Redirecting to Ripples..." allowIndexing={false} />
            <section className={styles.SaltedgeSuccess}>
                <StaticImage src={spinner} alt="spinner" />
            </section>
        </>
    );
};

export default SaltedgeRenewSuccess;
